import { lazy, Suspense } from "react";

const PLayout = lazy(() => import("../components/PLayout/index"));
const Login = lazy(() => import("../pages/User/login"));

// const AlgorithmDetail = lazy(() => import("../pages/Algorithm/index"));
// const AlgorithmList = lazy(() => import("../pages/Algorithm/list"));
const OrderList = lazy(() => import("../pages/Order/list"));
const InvoiceList = lazy(() => import("../pages/Order/invoice"));
const PayList = lazy(() => import("../pages/Order/pay"));

const Home = lazy(() => import("../pages/Home/index"));

const NewsAdd = lazy(() => import("../pages/News/add"));
const NewsEdit = lazy(() => import("../pages/News/edit"));
const NewsList = lazy(() => import("../pages/News/list"));

const CaseAdd = lazy(() => import("../pages/Case/add"));
const CaseEdit = lazy(() => import("../pages/Case/edit"));
const CaseList = lazy(() => import("../pages/Case/list"));

const MenuList = lazy(() => import("../pages/Menu/index"));
const MenuSet = lazy(() => import("../pages/Menu/set"));

const AlgorithmList = lazy(() => import("../pages/Algorithm/list"));
const AlgorithmAdd = lazy(() => import("../pages/Algorithm/index"));

const FeedbackList = lazy(() => import("../pages/Feedback/list"));

const TaskIndex = lazy(() => import("../pages/Task/index"));
const TaskAdd = lazy(() => import("../pages/Task/add"));
const TaskFile = lazy(() => import("../pages/Task/file"));
const TaskMine = lazy(() => import("../pages/Task/mine"));

const Seg = lazy(() => import("../pages/Task/seg"));

const lazyLoad = (children) => {
  return <Suspense fallback={<h1>Loading...</h1>}>{children}</Suspense>;
};

export const routers = [
  {
    path: "/",
    element: lazyLoad(<PLayout />),
    children: [
      {
        path: "/",
        element: lazyLoad(<Home />),
      },
      {
        path: "/order/list",
        element: lazyLoad(<OrderList />),
      },
      {
        path: "/invoice/list",
        element: lazyLoad(<InvoiceList />),
      },
      {
        path: "/pay/list",
        element: lazyLoad(<PayList />),
      },
      {
        path: "/news/add",
        element: lazyLoad(<NewsAdd />),
      },
      {
        path: "/news/edit",
        element: lazyLoad(<NewsEdit />),
      },
      {
        path: "/news/list",
        element: lazyLoad(<NewsList />),
      },
      {
        path: "/case/add",
        element: lazyLoad(<CaseAdd />),
      },
      {
        path: "/case/edit",
        element: lazyLoad(<CaseEdit />),
      },
      {
        path: "/case/list",
        element: lazyLoad(<CaseList />),
      },
      {
        path: "/menu/list",
        element: lazyLoad(<MenuList />),
      },
      {
        path: "/menu/set",
        element: lazyLoad(<MenuSet />),
      },
      {
        path: "/algorithm/list",
        element: lazyLoad(<AlgorithmList />),
      },
      {
        path: "/algorithm/add",
        element: lazyLoad(<AlgorithmAdd />),
      },
      {
        path: "/feedback/list",
        element: lazyLoad(<FeedbackList />),
      },
      // {
      //   path: "/task/index",
      //   element: lazyLoad(<TaskIndex />),
      // },
      // {
      //   path: "/task/add",
      //   element: lazyLoad(<TaskAdd />),
      // },
      // {
      //   path: "/task/file",
      //   element: lazyLoad(<TaskFile />),
      // },
      // {
      //   path: "/task/mine",
      //   element: lazyLoad(<TaskMine />),
      // },
    ],
  },
  {
    path: "/login",
    element: lazyLoad(<Login />),
  },
  {
    path: "/seg539",
    element: lazyLoad(<Seg />),
  },
];
